<template>
    <task-list-metrics-table
        v-if="taskListData.length"
        :agg-stream="aggStream"
        :table-user-settings="taskListTableUserSettings"
        :child-guides-map-by-step-id="childGuidesMapByStepId"
        :task-list-data="taskListData"
        :segments-map="segmentsMap"
        @tableSettingsUpdate="updateTaskListTableSettings" />
</template>

<script>
import TaskListMetricsTable from '@/stateless-components/guides/task-list/TaskListMetricsTable.vue';
import { connectAggregationToState, withComponent } from '@pendo/agg-connect';
import taskListMetrics from '@/aggregations/task-list-metrics';
import {
    getChildGuidesByStepId,
    getTaskLists,
    taskListTableUserSettingsName
} from '@/stateless-components/utils/task-list';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'GuideTaskListMetrics',
    components: {
        TaskListMetricsTable
    },
    props: {
        guide: {
            type: Object,
            Required: true,
            default: null
        },
        activeTimeSeries: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            taskListItems: [],
            taskListData: [],
            aggStream: null,
            childGuidesMapByStepId: {}
        };
    },
    computed: {
        ...mapGetters({
            getGuideById: 'guides/getGuideById',
            getTableUserSettingValueByName: 'userSettings/getCrossUISettingByName'
        }),
        ...mapState({
            activeId: (state) => state.guides.activeId,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            guides: (state) => state.guides.map,
            segmentsMap: (state) => state.filters.segmentsMap
        }),
        taskListTableUserSettings () {
            return this.getTableUserSettingValueByName(taskListTableUserSettingsName)?.value;
        }
    },
    async created () {
        await this.getTaskListData();
        this.childGuidesMapByStepId = getChildGuidesByStepId(this.taskListData, this.guides);

        this.createTaskListMetricsTableActivityAgg$();
    },
    methods: {
        ...mapActions({
            getBuildingBlocks: 'guides/getBuildingBlocks',
            updateUserSettingByName: 'userSettings/updateCrossUINamespaceSetting'
        }),
        async getTaskListData () {
            await this.getBuildingBlocks({ guide: this.guide });

            this.taskListData = getTaskLists(this.guide);
        },
        createTaskListMetricsTableActivityAgg$ () {
            const sourcesOfState = {
                component: this
            };
            this.aggStream = connectAggregationToState(
                taskListMetrics,
                withComponent({
                    timeSeries: () => ({ ...this.activeTimeSeries, period: 'dayRange' }),
                    segmentId: 'activeSegmentId',
                    parentGuideId: 'activeId',
                    childGuidesMapByStepId: 'childGuidesMapByStepId'
                })
            )(sourcesOfState);
        },
        updateTaskListTableSettings (settingsName, newUserSettings) {
            this.updateUserSettingByName({
                name: settingsName,
                value: newUserSettings
            });
        }
    }
};
</script>
