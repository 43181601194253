import { operators } from '@pendo/aggregations';
import capitalize from 'lodash/capitalize';
import { requestCsv } from '@/utils/aggregations';

/* eslint-disable id-length */
const o = operators;

/*
 * This function will move to shared-services to avoid duplicating code in
 * appengine and via-ui repos. Will move after pendo-io/shared-services#804 merges. APP-50803.
 */

// Due to a known bug around time correction (see https://pendo-io.atlassian.net/browse/APP-26960 for a detailed
// explanation), simply sorting events by browsertime results in events being out of order. This leads to problems
// when you want show these events in order grouped by step id, and when you want to determine the duration of time
// spent on each step. As you can see, the code needed to correct the order and calculate the step durations is rather
// complex. This helper function was created to keep this complexity defined in a single location.
export function buildCorrectedStepEventPipeline (keys, { includeMultiApp, appId }) {
    const getAppId = includeMultiApp ? { appId: 's.appId' } : {};
    const selectKeys = keys.reduce((keys, key) => ({ ...keys, [key]: key }), {});

    return o.pipeline(
        // first group by visitorId and create sequences of events by guideSeen/guideTimeout. The purpose of this
        // is to calculate step durations, drop intermediate guideAdvanced events, and retain exit events that are
        // relevant, but currently not in the correct order (the order is corrected in the next group operation)
        o.group(
            ['guideId', ...keys],
            o.groupField('s', {
                sequence: {
                    new: 'eventSubType(type) == "guideSeen" || eventSubType(type) == "guideTimeout"',
                    sort: ['browserTime']
                }
            })
        ),
        o.evaluate({
            timestamps: 'map(step, s, step[0].browserTime)',
            stepIds: 'map(step, s, step[0].guideStepId)'
        }),
        o.unwind('s', { index: 'index' }),
        // duration is calculated as (last event in sequence browserTime - first event in sequence browserTime).
        // fallback to (first event in NEXT sequence browserTime - first event in sequence browserTime)
        // Use zero for the duration if the current step id === the next step id (happens with consecutive display
        // events)
        o.evaluate({
            's[0].duration':
                'if (len(s)>1 || index+1 == len(timestamps) || stepIds[index+1] == s[0].guideStepId, s[-1].browserTime - s[0].browserTime, timestamps[index+1] - s[0].browserTime)',
            'seenStepTimestamp': 'timestamps[index]',
            'seenStepId': 'stepIds[index]',
            'prevSeenStepId': 'if(index > 0, stepIds[index-1], null)'
        }),
        o.select(
            Object.assign(
                {
                    s: 's',
                    parentAccountId: 'parentAccountId',
                    seenStepTimestamp: 'seenStepTimestamp',
                    seenStepId: 'seenStepId',
                    prevSeenStepId: 'prevSeenStepId'
                },
                selectKeys
            )
        ),
        o.unwind('s', { index: 'index' }),
        o.select(
            Object.assign(
                {
                    guideId: 's.guideId',
                    guideStepId: 's.guideStepId',
                    type: 's.type',
                    browserTime: 's.browserTime',
                    visitorId: 's.visitorId',
                    stepStatus: 's.stepStatus',
                    duration: 's.duration',
                    seenStepId: 'seenStepId',
                    seenStepTimestamp: 'seenStepTimestamp',
                    prevSeenStepId: 'prevSeenStepId',
                    parentAccountId: 'parentAccountId',
                    ...getAppId
                },
                selectKeys
            )
        ),
        includeMultiApp &&
            o.merge(
                ['appId'],
                o.mappings({
                    appName: 'appName'
                }),
                o.pipeline(
                    o.sources.apps({ appId }),
                    o.select({
                        appName: 'displayName',
                        appId: 'id'
                    })
                )
            ),
        includeMultiApp &&
            o.evaluate({
                appName: 'appName'
            }),
        // filter out events in the sequence with a different stepId, for example intermediate guideAdvanced events.
        // The exception is exitEvents that are in the wrong sequence, but whose order will be corrected below.
        // In this case, the exitEvent timestamp should be fairly close (<1500ms) to the time stamp of the display
        // event
        o.filter(
            'guideStepId == seenStepId || (guideStepId == prevSeenStepId && browserTime - seenStepTimestamp < 1500)'
        ),
        o.group(
            ['guideId', ...keys, 'guideStepId'],
            o.groupField('s', {
                sequence: {
                    new: 'eventSubType(type) == "guideSeen" || eventSubType(type) == "guideTimeout"',
                    sort: ['browserTime']
                }
            })
        ),
        o.unwind('s', { index: 'index' }),
        // re-calculate duration if the last event in the sequence is an exit event. This allows the duration
        // to be calculated with corrected step event order as (last event - first event), which is better aligned
        // with how the data is displayed in the UI and how product has determined step duration should be calculated
        o.evaluate({
            's[0].duration':
                'if(eventSubType(s[-1].type) == "guideAdvanced" || eventSubType(s[-1].type) == "guideDismissed" || eventSubType(s[-1].type) == "guideSnoozed", s[-1].browserTime - s[0].browserTime, s[0].duration)',
            'visitorId': 's[0].visitorId'
        })
    );
}

export async function requestVisitorOrAccountIdCsv (spec, kind) {
    const fields = [
        {
            field: 'id',
            label: `${capitalize(kind)} ID`
        }
    ];

    return requestCsv(spec, fields);
}

export async function requestAppEngagementVisitorsCSV (spec, columns) {
    const fields = columns.map((col) => {
        return {
            field: col.prop,
            label: col.label
        };
    });

    return requestCsv(spec, fields);
}

// Customers may have arbitrarily high cardinalities in their metadata & event properties.
// Use the adjacent group-limit pipeline pattern as a safety net to avoid running out of memory
// on the backend during processing.
// 10,000 is the official limit. In the future, use the extra row to detect exceeding the limit
// and notifying the user accordingly.
export function buildGroupValueGrouping ({ columns, fields, useLimit = true, limit = 10001 }) {
    const group = [
        {
            group: {
                group: columns,
                fields
            }
        }
    ];
    if (useLimit) {
        const maxLimit = 10001;
        group.push({ limit: limit && limit <= maxLimit ? limit : maxLimit });
    }

    return group;
}

// In process of being deprecated in favor of formatStartAndEndStepsForAgg
export function getWorkflowSteps (workflowSteps, pages, features) {
    const steps = workflowSteps.reduce(
        (result, step) => {
            const countable = step.countableKind === 'Feature' ? features[step.countableId] : pages[step.countableId];
            if (!countable) return result;

            if (step.type === 'start' || step.type === 'end') {
                result[step.type] = {
                    appId: countable.appId,
                    id: countable.id,
                    type: step.countableKind.toLowerCase()
                };
            }

            return result;
        },
        {
            start: null,
            end: null
        }
    );
    if (!steps.start || !steps.end) throw new Error('Starting or Ending step is no longer available');

    return steps;
}

export function formatStartAndEndStepsForAgg (workflowSteps) {
    const startingStep = workflowSteps.find((step) => step.type === 'start');
    const endingStep = workflowSteps.find((step) => step.type === 'end');

    return {
        start: {
            appId: startingStep.appId,
            id: startingStep.countableId,
            type: startingStep.countableKind.toLowerCase()
        },
        end: {
            appId: endingStep.appId,
            id: endingStep.countableId,
            type: endingStep.countableKind.toLowerCase()
        }
    };
}
