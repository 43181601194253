<template>
    <div class="visitors-per-step-chart">
        <summary-chart-card
            :is-fetching="isFetching"
            spinner-type="feather"
            title="Visitors per Step">
            <template
                v-if="isCrossApp"
                #filters>
                <app-icon-tag
                    v-for="appId in appIds"
                    :key="appId"
                    :app="appMap[appId]" />
            </template>
            <template #chart>
                <div
                    ref="visitors-per-step"
                    :class="{ empty: totalVisitors <= 0 }"
                    class="pendo-highcharts-container" />
            </template>
            <template #summary>
                <div class="total-visitors-summary">
                    <div class="summary-chart-card__metric">
                        {{ totalVisitors }}
                    </div>
                    <div class="summary-chart-card__label">
                        {{ totalVisitorsLabel }}
                    </div>
                </div>
            </template>
        </summary-chart-card>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SummaryChartCard from '@/components/common/SummaryChartCard';
import AppIconTag from '@/components/filters/AppIconTag';
import { PendoGuideMetricsDrawerContent } from '@pendo/components';
import { getStepDisplayName } from '@/utils/guides';
import { getAppChartColor } from '@/utils/apps';
import { getVisitorsPerStep, getStepsSeenVisitorsAndAccounts } from '@/aggregations/visitors-per-step';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { addHrefToVisitors } from '@/utils/utils';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import { isCrossApp } from '@pendo/services/CrossAppGuides';

export default {
    name: 'VisitorsPerStepChart',
    components: {
        SummaryChartCard,
        AppIconTag
    },
    props: {
        csvDownloadAggId: {
            type: String,
            default: () => 'visitor-per-step-chart'
        },
        isPanelVisible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            chart: null,
            isFetching: false,
            visitorsPerStep: [],
            unsubscribeFilterBarListener: null,
            currentPoint: null
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            guide: 'guides/active',
            appMap: 'apps/appMapForActiveSubscription'
        }),
        appIds () {
            return get(this.guide, 'appIds', '');
        },
        isCrossApp () {
            return isCrossApp(this.guide);
        },
        visitorUrl () {
            return '/analytics/visitors';
        },
        totalVisitors () {
            return get(maxBy(this.visitorsPerStep, 'visitorCount'), 'visitorCount', 0);
        },
        totalVisitorsLabel () {
            return this.totalVisitors === 1 ? 'Visitor' : 'Visitors';
        },
        series () {
            const resultsByStepId = keyBy(this.visitorsPerStep, 'guideStepId');
            let index = 1;

            return [
                {
                    name: 'All Steps',
                    data: this.guide.steps.map((step) => {
                        const { visitorCount = 0, viewCount = 0 } = resultsByStepId[step.id] || {};
                        const name = getStepDisplayName(step.id, this.guide);
                        const visitorCountPercent = Math.round((100 * visitorCount) / this.totalVisitors);
                        const appId = get(step, 'appIds[0]', this.guide.appId);

                        return {
                            name,
                            id: step.id,
                            visitorCount,
                            viewCount,
                            visitorCountPercent,
                            count: visitorCount,
                            y: visitorCount,
                            color: getAppChartColor(this.appMap[appId]),
                            className: `step-${index++}`
                        };
                    })
                }
            ];
        }
    },
    watch: {
        'series': function () {
            if (!this.chart) return;
            this.chart.update({
                series: this.series
            });
        },
        'guide.steps.length': function () {
            this.initChart();
        },
        'isPanelVisible': function (val) {
            if (!val) {
                // If the side panel is closed, reset the current point
                this.currentPoint = null;
            }
        }
    },
    created () {
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.refreshChart();
        });
    },
    mounted () {
        this.initChart();
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async initChart () {
            await this.refreshChart();
            const chartConfig = this.getChartConfig();
            if (this.$refs['visitors-per-step']) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['visitors-per-step'], chartConfig);
            }
        },
        async refreshChart () {
            this.isFetching = true;
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
            this.visitorsPerStep = await getVisitorsPerStep({
                appIds: this.appIds,
                guideId: this.guide.id,
                timeSeries,
                segmentId: this.activeSegmentId
            });
            if (this.currentPoint) {
                this.openDrawer(this.currentPoint);
            }

            this.isFetching = false;
        },
        getChartConfig () {
            return {
                series: this.series,
                chart: {
                    type: 'column'
                },
                plotOptions: {
                    column: {
                        events: {
                            click: (event) => this.openDrawer(event.point)
                        },
                        stacking: 'normal',
                        cursor: 'pointer'
                    }
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    categories: this.series[0].data.map((step) => step.name),
                    crosshair: false
                },
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        style: {
                            color: '#BABCC5',
                            fontWeight: 'normal',
                            textOutline: null
                        },
                        formatter () {
                            return this.total !== 0 ? this.total : '';
                        }
                    },
                    labels: {
                        align: 'center'
                    },
                    title: {
                        text: 'Visitors'
                    },
                    min: 0,
                    allowDecimals: false
                },
                tooltip: {
                    pointFormat: '<b>Visitors: </b>{point.y}<br/>{point.visitorCountPercent}% of Total Visitors',
                    useHTML: true
                }
            };
        },
        async getStepsSeenVisitorsAndAccountsWithHref (aggParams) {
            const response = await getStepsSeenVisitorsAndAccounts(aggParams);
            const visitorsWithHref = addHrefToVisitors(response[0]);

            return visitorsWithHref;
        },
        async openDrawer (point) {
            this.currentPoint = point;
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
            this.$emit('update-current-step', point);

            this.setSidePanelState({
                point,
                loading: true
            });
            const visitors = await this.getStepsSeenVisitorsAndAccountsWithHref({
                appIds: this.appIds,
                guideId: this.guide.id,
                guideStepId: point.id,
                timeSeries,
                segmentId: this.activeSegmentId
            });

            this.$emit('update-steps-seen-visitors-and-accounts', visitors);

            this.setSidePanelState({
                point,
                data: visitors,
                loading: false
            });
        },
        setSidePanelState ({ point, data, loading, csvModalStatus = 'loading', csvUrl = '' }) {
            const state = {
                header: {
                    props: {
                        title: point.name
                    }
                },
                body: {
                    component: PendoGuideMetricsDrawerContent,
                    props: {
                        csvDownloadAggId: this.csvDownloadAggId,
                        data,
                        loading,
                        csvModalStatus,
                        csvUrl,
                        visitorUrl: this.visitorUrl,
                        showAccountsTab: false,
                        theme: 'app'
                    }
                }
            };
            this.$emit('open-side-panel', { ...state, visible: true });
        }
    }
};
</script>
<style lang="scss" scoped>
.total-visitors-summary {
    grid-row: 1 / span 2;
    grid-column: 1/1;
    display: grid;
    align-content: center;
}
</style>
