<template>
    <main class="track-events-list">
        <pendo-table
            v-pendo-loading:feather="isFetchingTrackEventsList"
            csv-download
            resizable
            title="Track Events"
            :max-height="600"
            :data="formattedTrackEventsList"
            :filters="filters"
            row-key="id"
            :default-sort="defaultSort"
            :columns="columns">
            <template #name="{ row }">
                <div class="track-event-list--table-name">
                    <router-link :to="{ name: 'trackEventDetails', params: { trackEventId: row.id } }">
                        {{ row.name }}
                    </router-link>
                </div>
            </template>
            <template #displayName=" { row }">
                <pendo-app-display :apps="row.app" />
            </template>
            <template #empty>
                <div class="track-events-list--table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        No data found.
                    </span>
                </div>
            </template>
        </pendo-table>
    </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { PendoIcon, PendoTable, PendoLoading, PendoAppDisplay } from '@pendo/components';
import { countableType } from '@pendo/tagging';

export default {
    name: 'AnalyticsTrackEventList',
    components: {
        PendoIcon,
        PendoTable,
        PendoAppDisplay
    },
    directives: {
        PendoLoading
    },
    props: {
        searchString: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            unsubscribeFilterBarListener: null,
            defaultSort: {
                prop: 'displayName',
                order: 'ascending'
            },
            isFetchingTrackEventList: false,
            columns: [
                {
                    label: 'Track Event Name',
                    prop: 'displayName',
                    slot: 'name',
                    sortable: true,
                    width: 288
                },
                {
                    label: 'Type',
                    prop: 'type',
                    sortable: true,
                    width: 120
                },
                {
                    label: 'Application',
                    slot: 'displayName',
                    sortable: true
                },
                {
                    label: 'Number of Visitors',
                    prop: 'numVisitors',
                    async: true,
                    sortable: true
                },
                {
                    label: 'Number of Track Events',
                    prop: 'numEvents',
                    async: true,
                    sortable: true
                },
                {
                    label: 'Description',
                    prop: 'description',
                    sortable: false,
                    showOverflow: 'clamp',
                    width: 250,
                    minWidth: 130
                }
            ]
        };
    },
    computed: {
        ...mapState({
            isFetchingTrackEventsList: (state) => state.trackEvents.isFetchingWithAnalytics
        }),
        ...mapGetters({
            trackEventList: 'trackEvents/listAllWithAnalytics'
        }),
        filters () {
            return [
                {
                    prop: ['name', 'app.displayName'],
                    value: this.searchString
                }
            ];
        },
        formattedTrackEventsList () {
            return (
                this.trackEventList?.map((trackEvent) => {
                    return { ...trackEvent, type: countableType(trackEvent) };
                }) || []
            );
        }
    }
};
</script>

<style lang="scss">
.track-events-list {
    &--table {
        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }
    }

    .pendo-table__header {
        .pendo-table__column {
            text-transform: capitalize;
        }
    }
}
</style>
