import { BuildingBlock, BuildingBlockLayouts, BuildingBlockProperties } from '@pendo/services/BuildingBlocks';
import { TaskList } from '@pendo/services/TaskList';

export const TASK_LIST_COLUMNS = [
    {
        prop: 'name',
        label: 'Guide Name',
        visible: true,
        minWidth: 260
    },
    {
        prop: 'displayName',
        label: 'Task Display Text',
        visible: true,
        minWidth: 260
    },
    {
        prop: 'clicks',
        label: 'Task Clicks',
        visible: true,
        minWidth: 180
    },
    {
        prop: 'state',
        label: 'Status',
        visible: true,
        minWidth: 180
    },
    {
        prop: 'segment',
        label: 'Segment',
        visible: true,
        minWidth: 180
    },
    {
        prop: 'views',
        label: 'Views',
        visible: true,
        minWidth: 180
    },
    {
        prop: 'visitors',
        label: 'Unique Visitors',
        visible: true,
        minWidth: 260
    }
];

export const taskListTableUserSettingsName = 'taskListMetricsTable';

export const formatTaskListData = (step, stepIndex) => {
    const blocks = JSON.parse(step.buildingBlocks);

    const taskListBlock = TaskList.getTaskListBlock(blocks);

    if (!taskListBlock) {
        return;
    }

    const {
        templateChildren,
        web: { taskListId }
    } = taskListBlock;

    const parentBlock = BuildingBlock.findBlocksByWebId(blocks, taskListId, 'taskListId')?.find(
        (block) => block.widget === BuildingBlockLayouts.widgetIds.textViewBlock
    );
    const header = parentBlock
        ? BuildingBlockProperties.getPropertyByName(parentBlock.properties, 'markdownText').value
        : '';

    return { templateChildren, header, stepId: step.id, id: taskListId, stepIndex };
};

export const getTaskLists = (guide) => {
    return guide.steps.reduce((acc, step, index) => {
        const taskList = formatTaskListData(step, index);

        if (taskList) {
            acc.push(taskList);
        }

        return acc;
    }, []);
};

export const getChildGuidesByStepId = (taskLists, guides) => {
    return taskLists.reduce((acc, taskList) => {
        const childGuides = taskList.templateChildren.map((child) => guides[child.id]).filter((x) => x);

        if (childGuides.length) {
            acc[taskList.stepId] = childGuides;
        }

        return acc;
    }, {});
};
