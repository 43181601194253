import get from 'lodash/get';
import invoke from 'lodash/invoke';
import { http } from '@pendo/http';
import { isValidUrl } from '@pendo/services/Formatters';

export const LEGACY_APP_ID = -323232;

export function flattenTrainingAttrs (entityList, additionalProps = {}) {
    return entityList.map((entity) => {
        let flatApps;
        const { trainingAttributes, featureFlags, applications } = entity;
        const newEntity = { ...entity, ...trainingAttributes, ...additionalProps };
        if (trainingAttributes && trainingAttributes.whiteLabelSettings) {
            newEntity.color = get(trainingAttributes, 'whiteLabelSettings.primaryColor');
        }
        if (featureFlags) newEntity.featureFlags = featureFlags;
        if (applications) {
            flatApps = flattenTrainingAttrs(applications);
            newEntity.applications = flatApps;
        }

        return newEntity;
    });
}

export function hydrateSubscriptionAccounts (subscriptions = []) {
    return subscriptions.map((sub) => {
        if (!sub.accounts) return { ...sub };

        const accounts = Object.entries(sub.accounts).reduce((map, [id, acct]) => {
            map[id] = {
                ...acct,
                id,
                uid: `${sub.id}:${id}`
            };

            return map;
        }, {});

        return { ...sub, accounts };
    });
}

export function createAccountMap (subscriptions, subscriptionId) {
    const sub = subscriptions[subscriptionId];
    const displayName = get(sub, 'displayName');
    const map = displayName
        ? {
            [displayName]: { ...sub, id: displayName }
        }
        : {};

    return map;
}

export function isValidLookasideHost (url) {
    const urlRegex = new RegExp('^(?!https://|http://).+([a-zA-Z0-9].[a-zA-Z]{2,})(?<!/)$', 'i');

    return !url || (typeof url === 'string' && !!url.match(urlRegex)); // empty/null lookasideHost is valid
}

export function getUrlValidator (validator) {
    return (rule, value, callback) => {
        if (!validator(value)) {
            callback(new Error('URL is invalid.'));

            return;
        }
        callback();
    };
}

export function isValidLaunchDesignerForm ({ url, lookasideHost }) {
    return isValidLookasideHost(lookasideHost) && isValidUrl(url);
}

export const lookasideHostValidationRules = [
    {
        required: false,
        message: 'Please input a valid url excluding https:// and trailing slash',
        trigger: 'change'
    },
    {
        required: false,
        message: 'Please input a valid url excluding https:// and trailing slash',
        validator: getUrlValidator(isValidLookasideHost),
        trigger: 'blur'
    }
];

export async function normalizeUrl (url) {
    // if the URL already contains encoded characters, we need to decode them first so they
    // don't get encoded again below.
    try {
        url = decodeURIComponent(url);
    } catch (err) {
        return url;
    }

    return http.post('/api/s/_SID_/helper/urlnorm', { url: encodeURI(url) }).then((res) => res.data);
}

export function inDevEnvironment () {
    const env = get(window, '__via_info__.env', '');
    if (!env.includes('prod')) return true;

    return false;
}

// use kebab case
// add existing/new Pendo track event names to use and create adopt versions with `sendTrackEvent`
export const TRACK_EVENT_NAMES = {
    aiGuideCreated: 'ai-guide-created',
    guideLanguageImport: 'guide-language-import',
    guidePublished: 'guide-published',
    resourceCenterLanguageImport: 'resource-center-language-import',
    workflowsListTableUserSettingMigrated: 'workflows-list-table-user-setting-migrated',
    launchPreview: 'launch-preview'
};

export function sendPendoTrackEvent ({ trackEventKey, properties = {}, adoptSpecific = true }) {
    let trackEventName = TRACK_EVENT_NAMES[trackEventKey];
    if (!trackEventName) throw new Error(`Invalid trackEventKey: ${trackEventKey}`);
    if (adoptSpecific) trackEventName = `adopt-${trackEventName}`;

    invoke(window, 'pendo.track', trackEventName, properties);
}

// periods cause google appengine's magic routing to break
export function encodeIdForUri (id) {
    const periodRegex = /\./g;

    return encodeURIComponent(id).replace(periodRegex, '%2E');
}

export function addHrefToVisitors ({ visitors = [], visitorUrl = '/analytics/visitors' }) {
    return {
        visitors: visitors
            ? visitors.map(({ id }) => ({ id, href: `${visitorUrl}/${encodeURIComponent(encodeIdForUri(id))}` }))
            : []
    };
}

export function propName (obj, type) {
    return Object.keys(obj).find((key) => obj[key] === type);
}
